<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Cash Flow</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="cashFlowInsert">
              <div class="card-body">
                <v-progress-circular
                  v-if="loading"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <div v-else>
                  <div class="form-group">
                    <label>Account Code</label>
                    <v2-select
                      v-model="form.account_code_id"
                      :options="account_code"
                      :reduce="(code) => code.id"
                      label="name"
                    >
                    </v2-select>
                    <small class="text-danger" v-if="errors.account_code_id">{{
                      errors.account_code_id[0]
                    }}</small>
                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <label>Status</label>
                      <select v-model="form.status" class="form-control">
                        <option value="">Select Status</option>
                        <option value="IN">IN</option>
                        <option value="OUT">OUT</option>
                      </select>
                      <small class="text-danger" v-if="errors.status">{{
                        errors.status[0]
                      }}</small>
                    </div>
                    <div class="form-group col">
                      <label>Date</label>
                      <input
                        type="date"
                        class="form-control"
                        v-model="form.reported_at"
                      />
                      <small class="text-danger" v-if="errors.status">{{
                        errors.status[0]
                      }}</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Payment Type</label>
                    <model-list-select
                      :list="payment_type"
                      v-model="form.payment_type_id"
                      option-value="id"
                      option-text="name"
                      placeholder="Select Payment Type"
                    >
                    </model-list-select>
                    <small class="text-danger" v-if="errors.payment_type_id">{{
                      errors.payment_type_id[0]
                    }}</small>
                  </div>
                  <div class="form-group">
                    <label>Amount</label>
                    <money v-model="form.amount" class="form-control"></money>

                    <small class="text-danger" v-if="errors.amount">{{
                      errors.amount[0]
                    }}</small>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      class="form-control"
                      v-model="form.description"
                      style="height:100px"
                    ></textarea>
                    <small class="text-danger" v-if="errors.description">{{
                      errors.description[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";

export default {
  name: "FormCashFlow",
  data() {
    return {
      name: "Form Cash Flow",
      form: {
        account_code_id: "",
        payment_type_id: "",
        status: "",
        amount: "",
        description: "",
        reported_at: "",
      },
      isSubmitting: false,
      errors: {},
      edit: false,
      account_code: [],
      department: [],
      venue: [],
      supplier: [],
      payment_type: [],
      loading: false,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getData();
    }
    this.getAccounting();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("cashflow/" + id)
        .then((result) => {
          this.form.account_code_id = result.data.data.account_code_child_id;
          this.form.amount = result.data.data.amount;
          this.form.payment_type_id = result.data.data.payment_type_id;
          this.form.status = result.data.data.status;
          this.form.description = result.data.data.description;
          this.form.reported_at = result.data.data.reported_at;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAccounting() {
      this.loading = true;
      apiGet("account_code/not-paginate")
        .then((result) => {
          this.account_code = result.data.data;
          apiGet("payment_type")
            .then((result) => {
              this.loading = false;
              this.payment_type = result.data.data;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cashFlowInsert() {
      if (this.edit === false) {
        this.isSubmitting = true;
        axios
          .post(this.$store.state.api + "cashflow/store", this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Cash Flow has been created!");
            this.$router.push({ name: "CashFlow" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = true;

        let id = this.$route.params.id;
        axios
          .post(this.$store.state.api + "cashflow/update/" + id, this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Cash Flow has been updated!");
            this.$router.push({ name: "CashFlow" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
